package com.matprisguru.pages

import androidx.compose.runtime.Composable
import com.matprisguru.components.DescriptionText
import com.matprisguru.components.HeaderLayout
import com.matprisguru.components.widgets.GradientBox
import com.matprisguru.navigation.Screen
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.core.rememberPageContext
import com.varabyte.kobweb.silk.components.text.SpanText
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.H2
import org.jetbrains.compose.web.dom.H4
import org.jetbrains.compose.web.dom.Text


@Page
@Composable
fun RulesPage(){
    val context = rememberPageContext()

    HeaderLayout(context){
        GradientBox(
            contentAlignment = Alignment.Center,
            modifier = Modifier.fillMaxWidth()
        ) {
            Column(
                modifier = Modifier.fillMaxHeight().fillMaxWidth(60.percent).padding(4.px)
                    .onClick {
                        //context.router.navigateTo(Screen.HomePage.route)
                    }
                    .alignContent(AlignContent.Center),
                horizontalAlignment = Alignment.CenterHorizontally
            ){
                H2(attrs = {style {
                    marginTop(32.px)
                    marginBottom(40.px)
                }}
                ) { Text("Lotteri Regler") }


                DescriptionText(
                    text = "Jo flere og større kvitteringer du sender, " +
                            "og jo tidligere du sender dem, desto større er sjansen din til å vinne.",
                )

                H4(){
                    SpanText("Hvordan det fungerer:")
                }

                DescriptionText(
                    text = "Du sender inn en digital kvittering fra butikkens app eller nettportal, eller et bilde av en fysisk kvittering fra Rema 1000, Kiwi eller Coop Extra. Tellingen starter med 0 produkter. Hver gang vi mottar en kvittering, registrerer vi hvor mange nye unike produkter den inneholder. Når vi har fått 500 unike produkter, blir belønningen utløst. Vi trekker en vinner. Vinnersjansen din er proporsjonal med hvor mange nye produkter du har bidratt med. Hvis du for eksempel bidrar med 10 unike produkter, blir vinnersjansen din 10/500 = 2 %. Hvis du bidrar med 100 unike produkter, " +
                            "blir vinnersjansen din 100/500 = 20 %. Når belønningen er utløst, starter vi tellingen på nytt."
                )

                DescriptionText(
                    text = "Vær obs: tallene kan endres i fremtiden, avhengig av budsjettet vi har."
                )

                H4(){
                    SpanText("Regler:")
                }

                val rules = listOf(
                    "Kvitteringen må være fra Rema 1000, Kiwi, eller Coop Extra.",
                    "Kvitteringen må være godt lesbar.",
                    "Kvitteringen må ha minst 10 forskjellige produkter på.",
                    "Kvitteringsdatoen må være synlig på kvitteringen.",
                    "Kvitteringsdatoen kan ikke være eldre enn 30 dager."
                )

                rules.forEachIndexed { index, rule ->
                    DescriptionText("${index + 1}. $rule")
                }
            }
        }

    }
}