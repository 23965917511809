package com.matprisguru.model

import kotlinx.serialization.Serializable

@Serializable
data class Product(
    val store: String,
    val description: String,
    val price: Double,
    val date: String,
    val imageUrl: String
)

@Serializable
data class WinningChance(
    val _id: String,
    val products: Int
)

@Serializable
data class Nickname(
    val _id: String,
    val nickname: String
)

@Serializable
data class CombinedData(
    val _id: String,
    val nickname: String,
    val products: Int
)