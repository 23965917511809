package com.matprisguru.utility

import kotlinx.coroutines.await
import kotlinx.datetime.Clock
import kotlinx.datetime.LocalDateTime
import kotlinx.datetime.TimeZone
import kotlinx.datetime.internal.JSJoda.DateTimeParseException
import kotlinx.datetime.internal.JSJoda.LocalDate
import kotlinx.datetime.toLocalDateTime
import org.khronos.webgl.ArrayBuffer
import org.khronos.webgl.Uint8Array
import org.khronos.webgl.get
import org.w3c.files.File
import org.w3c.files.FileReader
import kotlin.js.Promise

fun isDateStringParsable(dateString: String): Boolean {
    return try {
        LocalDateTime.parse(dateString)
        true
    } catch (e: DateTimeParseException) {
        false
    }
}

suspend fun fileToByteArray(file: File): ByteArray {
    // Create a FileReader
    val reader = FileReader()

    // Use a Promise to read the file as an ArrayBuffer
    val arrayBufferPromise = Promise { resolve, reject ->
        reader.onload = { resolve(reader.result as ArrayBuffer) }
        reader.onerror = { reject(Exception("File reading error")) }
        reader.readAsArrayBuffer(file)
    }

    // Wait for the ArrayBuffer to be ready
    val arrayBuffer = arrayBufferPromise.await()

    // Convert the ArrayBuffer to ByteArray
    val uint8Array = Uint8Array(arrayBuffer)
    val byteArray = ByteArray(uint8Array.length)

    for (i in 0 until uint8Array.length) {
        byteArray[i] = uint8Array[i]
    }

    return byteArray
}

fun getCurrentTimestamp(): String {
    val now = Clock.System.now()
        .toLocalDateTime(TimeZone.currentSystemDefault())

    val year = now.year
    val month = now.monthNumber.toString().padStart(2, '0')
    val day = now.dayOfMonth.toString().padStart(2, '0')
    val hour = now.hour.toString().padStart(2, '0')
    val minute = now.minute.toString().padStart(2, '0')
    val second = now.second.toString().padStart(2, '0')
    val millisecond = now.nanosecond.div(1_000_000).toString().padStart(3, '0')

    return "$year$month$day$hour$minute$second$millisecond"
}