package com.matprisguru.model

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.json.JsonContentPolymorphicSerializer
import kotlinx.serialization.json.JsonElement
import kotlinx.serialization.json.jsonObject

@Serializable(ChancesListResponseSerializer::class)
sealed class ApiChancesListResponse {

    @Serializable
    @SerialName("loading")
    object Loading: ApiChancesListResponse()

    @Serializable
    @SerialName("success")
    data class Success(val data: List<WinningChance>): ApiChancesListResponse()

    @Serializable
    @SerialName("error")
    data class Error(val message: String): ApiChancesListResponse()
}

object ChancesListResponseSerializer: JsonContentPolymorphicSerializer<ApiChancesListResponse>(ApiChancesListResponse::class){
    override fun selectDeserializer(element: JsonElement) = when {
        "data" in element.jsonObject -> ApiChancesListResponse.Success.serializer()
        "message" in element.jsonObject -> ApiChancesListResponse.Error.serializer()
        else -> ApiChancesListResponse.Loading.serializer()
    }

}