package com.matprisguru.model

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.json.JsonContentPolymorphicSerializer
import kotlinx.serialization.json.JsonElement
import kotlinx.serialization.json.jsonObject

@Serializable(NicknamesListResponseSerializer::class)
sealed class ApiNicknameListResponse {

    @Serializable
    @SerialName("loading")
    object Loading: ApiNicknameListResponse()

    @Serializable
    @SerialName("success")
    data class Success(val data: List<Nickname>): ApiNicknameListResponse()

    @Serializable
    @SerialName("error")
    data class Error(val message: String): ApiNicknameListResponse()
}

object NicknamesListResponseSerializer: JsonContentPolymorphicSerializer<ApiNicknameListResponse>(ApiNicknameListResponse::class){
    override fun selectDeserializer(element: JsonElement) = when {
        "data" in element.jsonObject -> ApiNicknameListResponse.Success.serializer()
        "message" in element.jsonObject -> ApiNicknameListResponse.Error.serializer()
        else -> ApiNicknameListResponse.Loading.serializer()
    }

}