package com.matprisguru.components

import androidx.compose.runtime.Composable
import com.matprisguru.components.widgets.ButtonShape
import com.matprisguru.components.widgets.LinkButton
import com.matprisguru.navigation.Screen
import com.varabyte.kobweb.browser.dom.ElementTarget
import com.varabyte.kobweb.compose.css.*
import com.varabyte.kobweb.compose.css.functions.CSSFilter
import com.varabyte.kobweb.compose.css.functions.blur
import com.varabyte.kobweb.compose.css.functions.saturate
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.core.PageContext
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.icons.fa.FaInfo
import com.varabyte.kobweb.silk.components.icons.fa.FaUpload
import com.varabyte.kobweb.silk.components.icons.fa.IconSize
import com.varabyte.kobweb.silk.components.overlay.Tooltip
import com.varabyte.kobweb.silk.components.text.SpanText
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.H1
import org.jetbrains.compose.web.dom.Li

val headerHeight = 80.px
private val BUTTON_MARGIN = Modifier.margin(0.px, 10.px)

@Composable
fun HeaderLayout(
    context: PageContext,
    content: @Composable () -> Unit
){

    val iconSize = headerHeight*0.50
    Box(
        modifier = Modifier
            .fillMaxSize()
            .overflow(overflowX = Overflow.Auto, overflowY = Overflow.Inherit)
            /*
            .backgroundImage(
                linearGradient(
                    dir = LinearGradient.Direction.ToRight,
                    from = rgb(20,60,100),
                    to = rgb(30,80,170)
                )
            )
             */
    ){

        Row(
            modifier = Modifier
                .fillMaxSize()
                .padding(12.px)
                .height(headerHeight)
                .borderBottom(1.px, LineStyle.Solid, Color.black)
                .position(Position.Fixed)
                .top(0.px)
                .zIndex(1)
                .backdropFilter(
                    saturate(180.percent), blur(20.px)
                )


            ,
            horizontalArrangement = Arrangement.Center
        ){
            Column(
                modifier = Modifier.fillMaxHeight().fillMaxWidth(90.percent)
            ){
                Row(
                    modifier = Modifier.fillMaxSize(),
                    verticalAlignment = Alignment.CenterVertically

                ){

                    Image(
                        src="gurulogo2.svg",
                        modifier= Modifier
                            .color(Colors.White)
                            .cursor(Cursor.Pointer)
                            .padding(right=8.px)
                            .fillMaxHeight(50.percent)
                            .onClick { context.router.navigateTo(Screen.HomePage.route) }
//                            .border(1.px, LineStyle.Solid, Color.red)
                    )
                    SpanText("Matprisguru",
                                Modifier.color(Color.green).fontWeight(FontWeight.Bolder).fontSize(FontSize.XLarge)
                                    .cursor(Cursor.Pointer)
                                    .onClick { context.router.navigateTo(Screen.HomePage.route) }
//                                    .border(1.px, LineStyle.Solid, Color.blue)
                            )

                    Row(
                        modifier = Modifier.fillMaxSize()
                            //.border(1.px, style = LineStyle.Dotted, color = Color.red)
                        ,
                        verticalAlignment = Alignment.CenterVertically,
                        horizontalArrangement = Arrangement.End
                    ){

                        LinkButton(Screen.AboutPage.route, BUTTON_MARGIN, shape=ButtonShape.CIRCLE){
                            FaInfo()
                        }
                        Tooltip(ElementTarget.PreviousSibling, "Litt mer informasjon", modifier = Modifier.zIndex(1))


                        LinkButton(Screen.ContributePage.route, BUTTON_MARGIN, shape=ButtonShape.CIRCLE){
                            FaUpload()
                        }
                        Tooltip(ElementTarget.PreviousSibling, "Last opp data", modifier = Modifier.zIndex(1))


                    }

                }
            }


        }
        Box(
            modifier = Modifier.fillMaxSize().margin(top=headerHeight)

        ){
            //Image("backgroundblue.png", Modifier.fillMaxWidth())
            Column(
                modifier = Modifier.fillMaxSize(),//.margin(top=headerHeight),
                verticalArrangement = Arrangement.Top,
                horizontalAlignment = Alignment.CenterHorizontally
            ) {
                content()
            }
        }

    }
}